import React from "react";
import SEO from "components/seo";
import AppContentContainer from "components/layouts/appLayout/AppContentContainer";

const FoundryPage = () => (
  <AppContentContainer spacing="extra">
    <SEO title="Items" />
    <h1>Items</h1>
    <p>
      This is where your cool magic items will be.... If I ever get around to
      it...
    </p>
  </AppContentContainer>
);

export default FoundryPage;
